// src/ContactForm.js
import React from 'react'

import {
    useForm,
    ValidationError,
} from '@formspree/react'

const HEADER_DESKTOP = {
    fontSize: '72px',
    textAlign: 'center',
}

const HEADER_MOBILE = {
    fontSize: '24px',
}

const DESKTOP_BUTTON = {
    //
}

const MOBILE_BUTTON = {
    //
}

export default function ContactForm({ isMobileDevice }) {
    const [state, handleSubmit] = useForm("mvobedqz")
    
    const buttonText = ( state.succeeded ) ? 'Thanks!' : 'Send'

    return (
        <div className="bg-black rounded-lg p-4 mt-24 mb-8 pt-4 pb-16">
            <div className="mt-4">
                <p
                    className="text-white font-black"
                    style={ isMobileDevice ? HEADER_MOBILE : HEADER_DESKTOP }
                >
                    Have an idea?
                </p>
            </div>

            <form
                onSubmit={ handleSubmit }
                className="max-w-md mx-auto mt-8"
            >
                <div className="mb-4">
                    <label
                        htmlFor="name"
                        className="block text-white font-bold mb-2"
                    >
                    Name:
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        required
                        className="w-full px-3 py-2 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-indigo-500"
                    />
                </div>

                <div className="mb-4">
                    <label
                        htmlFor="phoneNumber"
                        className="block text-white font-bold mb-2"
                    >
                    Phone Number:
                    </label>
                    <input
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    required
                    className="w-full px-3 py-2 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-indigo-500"
                    />
                </div>

                <div className="mb-4">
                    <label
                        htmlFor="message"
                        className="block text-white font-bold mb-2"
                    >
                    Tell us a little about your project:
                    </label>
                    <textarea
                    id="message"
                    name="message"
                    required
                    className="w-full px-3 py-2 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-indigo-500"
                    rows={4}
                    />
                </div>

                <button
                    type="submit"
                    className="bg-white text-black font-bold py-2 px-4 rounded lg:w-full"
                >
                    { buttonText }
                </button>
            </form>
        </div>
    )
}
