import React from 'react'

import DividerLineImg from '../../../images/divider-line.png'

const ROOT_DESKTOP = 'w-full flex items-center justify-center my-44'
const ROOT_MOBILE = 'w-full flex items-center justify-center my-24'


const IMG_DESKTOP = 'h-96'
const IMG_MOBILE = 'h-36'


export default function DividerLine({ isMobileDevice }) {
  return (
    <div className={ isMobileDevice ? ROOT_MOBILE : ROOT_DESKTOP }>
        <img
            className={ isMobileDevice ? IMG_MOBILE : IMG_DESKTOP }
            src={ DividerLineImg }
            alt=""
        />
    </div>
  )
}
