import React from 'react'

import WhiteLogo from '../../../images/white-f5.png'
import WindowsLogo from '../../../images/windows-logo.png'

const DESKTOP_LOGO = 'h-24'
const MOBILE_LOGO = 'h-24 mb-4'


export default function Footer({ isMobileDevice }) {
    return (
        <div className="flex flex-col items-center justify-center bg-black flex p-4">
            <img
                className={ isMobileDevice ? MOBILE_LOGO : DESKTOP_LOGO }
                src={ WhiteLogo }
            />

            <div className="text-white font-semibold text-center">
                <div className="mb-8">
                    <p
                        className=""
                    >
                        We don't have social media.
                    </p>

                    <p>
                        Call us, like the old times.
                    </p>

                    <a
                        className="text-white underline"
                        href="tel:+19189518807"
                    >
                        918-951-8807
                    </a>
                </div>

                <p>
                    Really missing the old times? <br /> Click below. I dare you.
                </p>

                <div className="flex items-center justify-center my-4">
                    <a
                        href="https://zda17.github.io/f5-new-site/windows95.html"
                        target="_blank"
                    >
                        <img
                            className="h-12"
                            src={ WindowsLogo }
                        />
                    </a>
                </div>

                <p>
                    Check out this app we built: <br /> MyLiftLog
                </p>

                <div className="flex items-center justify-center my-4">
                    <a
                        href="https://myliftlog.net"
                        target="_blank"
                    >
                        <img
                            className="h-12"
                            src="https://res.cloudinary.com/dsbgx3vru/image/upload/v1694404933/white-logo_xfamg0.png"
                        />
                    </a>
                </div>
            </div>
        </div>
    )
}
