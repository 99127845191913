import React from 'react'

import DividerLineSrc from './images/divider-line.png'


export default function DividerLine() {
    return (
        <div className="flex items-center justify-center my-12">
            <img
                alt=""
                className="h-36"
                src={ DividerLineSrc }
            />
        </div>
    )
}
