import React, { useEffect, useState } from 'react';
import Logo from '../../../images/logo-big.png';

export default function Header() {
  const h1Style = {
    fontSize: '250px',
    marginBottom: '10px',
    textAlign: 'center',
    position: 'relative',
    zIndex: '2',
  };

  const topTextStyle = {
    color: '#000',
    fontWeight: 800,
  };

  const bottomTextStyle = {
    color: '#fff',
    WebkitTextStrokeWidth: '12px',
    WebkitTextStrokeColor: '#000',
    fontWeight: 800,
  };

  const logoStyle = {
    height: '700px',
    marginTop: '-250px',
  };

  const [text, setText] = useState('WEBSITES');

  useEffect(() => {
    const rotateText = () => {
      // Rotate between "WEBSITES" and "APPS"
      setText((prevText) => (prevText === 'WEBSITES' ? 'APPS' : 'WEBSITES'));
    };

    const textRotationInterval = setInterval(rotateText, 2000);

    return () => {
      clearInterval(textRotationInterval);
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center">
      <h1 style={h1Style}>
        <span style={topTextStyle}>WE BUILD</span> <br />
        <span style={bottomTextStyle}>{text}</span>
      </h1>

      <img src={Logo} style={logoStyle} alt="Logo" />
    </div>
  );
}
