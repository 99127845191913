import React from 'react'

import DividerLine from './DividerLine'
import SectionHeader from './SectionHeader'
import SectionImg from './SectionImg'
import SectionText from './SectionText'

import CodingHeader from '../../../images/coding-header.png'
import IDEScreenshot from '../../../images/code.png'

import DesignHeader from '../../../images/Design-header.png'
import DesignOptions from '../../../images/design-options.png'

import HowItWorksHeader from '../../../images/how-it-works-header.png'
import HowItWorks from '../../../images/how-it-works.png'

import TestingLaunch from '../../../images/testing+launch-header.png'
import Launch from '../../../images/launch.png'

const SECTION_LAYOUT_STYLE = ''

const SECTION_HEADER_STYLE = 'flex flex-col items-center justify-center'

const SECTION_STYLE_DESKTOP = {
    marginLeft: '24px', // 'ml-24' is converted to 'marginLeft'
    display: 'flex',    // 'flex' is retained as it is
    flexDirection: 'row', // 'flex-row' is converted to 'row'
}

const SECTION_STYLE_MOBILE = {
    marginLeft: '2px', // 'ml-24' is converted to 'marginLeft'
    display: 'flex',    // 'flex' is retained as it is
    flexDirection: 'column-reverse', // 'flex-row' is converted to 'row'
}

const HOW_IT_WORKS_MOBILE_STYLE = 'h-24 w-auto'
const HOW_IT_WORKS_DESKTOP_STYLE = 'h-96 w-auto'


export default function TheProcess({ isMobileDevice }) {
    return (
        <div
            className=""
        >
            <div className={ SECTION_HEADER_STYLE }>
                <SectionHeader
                    isMobileDevice={ isMobileDevice }
                    src={ HowItWorksHeader }
                />

                <div className="">
                    <img
                        className={ isMobileDevice ? HOW_IT_WORKS_MOBILE_STYLE : HOW_IT_WORKS_DESKTOP_STYLE }
                        src={ HowItWorks }
                        alt=""
                    />
                </div>
            </div>
    
            <DividerLine
                isMobileDevice={ isMobileDevice }
            />
    
            <div className={ SECTION_LAYOUT_STYLE }>
                <div className={ SECTION_HEADER_STYLE }>
                    <SectionHeader
                        isMobileDevice={ isMobileDevice }
                        src={ DesignHeader }
                    />
                </div>
    
                <div style={ isMobileDevice ? SECTION_STYLE_MOBILE : SECTION_STYLE_DESKTOP }>
                    <SectionText
                        isMobileDevice={ isMobileDevice }
                        title="We give you options"
                        p1="Our design process starts with a simple question. Why? Why do your customers visit your page? 
                        What are they looking for? What do they need?"
                        p2="Before we ever write a line of code, we’ll collobarate with you and mockup 2-3 different versions of 
                        your mobile + desktop website for you to see. The final product might have the navbar from one, the 
                        buttons from another, and the color palette from the third.  We’ll rinse and repeat until it’s just 
                        right."
                        p3="This process usually takes 2-3 weeks, then we code."
                    />

                    <SectionImg
                        isMobileDevice={ isMobileDevice }
                        src={ DesignOptions }
                    />
                </div>
            </div>
    
            <DividerLine
                isMobileDevice={ isMobileDevice }
            />
    
            <div className={ SECTION_LAYOUT_STYLE }>
                <div className={ SECTION_HEADER_STYLE }>
                    <SectionHeader
                        isMobileDevice={ isMobileDevice }
                        src={ CodingHeader }
                    />
                </div>
    
                <div style={ isMobileDevice ? SECTION_STYLE_MOBILE : SECTION_STYLE_DESKTOP }>
                    <SectionText
                        isMobileDevice={ isMobileDevice }
                        title="We write custom &lt;code/&gt;"
                        p1="Aka “black magic”. Ever since we were young, we’ve wondered why things are they way they are. ”What’s 
                        under the hood?” Well, that’s where we live and work."
                        p2="If you’re tired of using templates & themes, dragging and dropping and updating plug-ins, then 
                        you’re in the right place. We don’t use WordPress, SquareSpace, Wix, Weebly or any other CMS you 
                        can think of. On the front-end, we use React or Vue. If a back-end is required, our favorite is Laravel (PHP)."
                        p3="Why don't we use a CMS? Well, that’s easy. Performance! And so we can do whatever we want! Plus, there’s just 
                        something about building things from scratch... This process usually takes 2-4 weeks."
                    />
        
                    <SectionImg
                        isMobileDevice={ isMobileDevice }
                        src={ IDEScreenshot }
                    />
                </div>
            </div>
    
            <DividerLine
                isMobileDevice={ isMobileDevice }
            />
    
            <div className={ SECTION_LAYOUT_STYLE }>
                <div className={ SECTION_HEADER_STYLE }>
                    <SectionHeader
                        isMobileDevice={ isMobileDevice }
                        src={ TestingLaunch }
                    />
                </div>
    
                <div style={ isMobileDevice ? SECTION_STYLE_MOBILE : SECTION_STYLE_DESKTOP }>
                    <SectionText
                        isMobileDevice={ isMobileDevice }
                        title="It’s showtime"
                        // p1="Did we mention all of our websites are blazing fast? But they're not just fast, they're accesible, always use best practices,
                        // and ready to scale for SEO. We guarantee Google Lighthouse scores of 95+ in all categories."
                        p2="If you already have a domain name, great. If not, we’ll get you one, help you setup custom emails, 
                        the whole 9 yards. All you need to do is sit back, relax, and enjoy your brand new beatiful website."
                        p3="This process usually takes 3-7 days."
                        showLink={ true }
                    />
        
                    <SectionImg
                        isMobileDevice={ isMobileDevice }
                        src={ Launch }
                    />
                </div>
            </div>
      </div>
    )
}
