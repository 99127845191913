import React, { useEffect, useState } from 'react';
import Logo from '../../../images/logo-big.png';

export default function MobileHeader() {
  const h1Style = {
    fontSize: '56px',
    position: 'relative',
    zIndex: '2',
  };

  const bottomTextStyle = {
    color: '#fff',
    WebkitTextStrokeWidth: '3px',
    WebkitTextStrokeColor: '#000',
  };

  const initialLogoStyle = {
    height: '300px',
    marginTop: '-65px',
  };

  const [logoStyle, setLogoStyle] = useState(initialLogoStyle);
  const [text, setText] = useState('WEBSITES');

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      if (scrollTop > 500) {
        // Update the logo's style
        setLogoStyle({
          height: '100px',
          marginTop: '20px',
          position: 'fixed',
          transition: 'all 0.75s ease-out',
          top: '5%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        });
      } else {
        // When at the top, reset the logo to its initial style
        setLogoStyle(initialLogoStyle);
      }
    };

    const rotateText = () => {
      // Rotate between "WEBSITES" and "APPS"
      setText((prevText) => (prevText === 'WEBSITES' ? 'APPS' : 'WEBSITES'));
    };

    const textRotationInterval = setInterval(rotateText, 2000);

    window.addEventListener('scroll', handleScroll);

    return () => {
      clearInterval(textRotationInterval);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center text-center h-screen">
      <h1 className="font-black" style={h1Style}>
        <span>WE BUILD</span> <br />
        <span style={bottomTextStyle}>{text}</span>
      </h1>

      <img src={Logo} style={logoStyle} alt="Logo" />
    </div>
  );
}
