import React from 'react'

const ROOT_DESKTOP = "w-1/2 h-auto"
const ROOT_MOBILE = "w-full h-auto my-8"


export default function SectionImg({ isMobileDevice, src }) {
    return (
        <img
            className={ isMobileDevice ? ROOT_MOBILE : ROOT_DESKTOP }
            src={ src }
            alt=""
        />
    )
}
