import React from 'react';

import LogoSrc from './images/myliftlog.png'


export default function Logo({ className }) {
    return (
        <a
            href="https://myliftlog.net"
        >
            <img
                alt=""
                className={ className }
                src={ LogoSrc }
            />
        </a>
    );
}
