import React from 'react'


export default function FeatureBlock({ imgSrc, subtext, text }) {
    return (
        <div className="my-12 text-center">
            <div className="mb-4">
                <div className="text-xl mb-2 font-semibold md:text-3xl">
                    { text }
                </div>

                <div className="px-8 md:w-1/4 md:m-auto md:text-xl">
                    { subtext }
                </div>
            </div>

            <div className="flex items-center justify-center">
                <img
                    alt=""
                    className="w-5/6 lg:w-1/4 xl:w-1/4 2xl:w-1/4 shadow-3xl"
                    src={ imgSrc }
                />
            </div>
        </div>
    )
}
