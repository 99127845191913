import React from 'react'

import { useMediaQuery } from 'react-responsive';

// import Nav from '../../Components/Nav'

import Header from './components/Header'
import MobileHeader from './components/MobileHeader'

import NoBullshit from './components/NoBullshit'
import TheProcess from './components/TheProcess'
import ContactForm from './components/ContactForm'

import Footer from './components/Footer'

const CONFIG = {
  false: {
    header: <Header />,
  },
  true: {
    header: <MobileHeader />,
  },
}


export default function Home() {
  const isMobileDevice = useMediaQuery({
    query: '(max-width: 767px)', // The maximum width for mobile devices
  })

  const { header } = CONFIG[isMobileDevice]

  return (
    <div>
        { header }

        <NoBullshit
            isMobileDevice={ isMobileDevice }
        />

        <TheProcess
            isMobileDevice={ isMobileDevice }
        />

        <ContactForm
            isMobileDevice={ isMobileDevice }
        />

        <Footer
            isMobileDevice={ isMobileDevice }
        />
    </div>
  )
}
