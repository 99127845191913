import AppPreviewImg from './components/images/app-preview.png'
import ExerciseShow from './components/images/exercise-show.png'
import PrefilledData from './components/images/prefill.png'
import ScheduleWorkout from './components/images/schedule-workout.png'
import WorkoutTemplate from './components/images/workout-template.png'

import DividerLine from './components/DividerLine'
import FeatureBlock from './components/FeatureBlock'
import Logo from './components/Logo'


export default function Welcome() {
    return (
        <div>
            <div className="bg-[#1F2937] px-4 py-2 fixed top-0 right-0 z-50 w-full">
                <div className="flex items-center justify-between">
                    <Logo
                        className="h-8"
                    />
                </div>
            </div>

            <div className="bg-[#1F2937] pl-4 pt-4 pr-4 pb-24 mt-12">
                <div className="flex flex-col items-center text-white mt-4 mx-12">
                    <h1 className="text-4xl mb-4">
                        MyLiftLog
                    </h1>

                    <h2 className="text-4xl relative font-black">
                        Quit{' '}
                        <span className="inline-block relative">
                            <span
                            className="absolute w-full h-1 bg-blue-500"
                            style={{
                                transform: 'rotate(-3deg)',
                                transformOrigin: '100% 100%',
                                top: '85%',
                            }}
                            />
                            <span className="relative text-white">Guessing</span>
                        </span>
                    </h2>


                    <p className="text-center mt-6 text-lg md:w-1/4 md:text-xl">The hardest part should be your lift, not remembering what you did last time.</p>
                </div>

                <div className="flex items-center justify-center mt-8">
                    <a
                        className="bg-blue-500 text-white py-2 px-12 rounded-2xl font-black text-lg"
                        href="https://myliftlog.net"
                    >
                        Go To Website >
                    </a>
                </div>

                <div className="mt-8 flex items-center justify-center mb-12">
                    <img
                        alt=""
                        className="w-5/6 lg:w-1/4 xl:w-1/4 2xl:w-1/4 shadow-3xl"
                        src={ AppPreviewImg }
                    />
                </div>
            </div>

            <div className="pl-4 pt-16 pr-4 pb-24 text-center bg-gray-200">
                <h2 className="text-[#1F2937] mt-12 text-3xl font-black">
                    Effortlessly track your fitness journey
                </h2>

                <DividerLine />

                <FeatureBlock
                    imgSrc={ PrefilledData }
                    subtext="Whether you did it last Tuesday or last year, your weights & reps are pre-filled. You can do 1 more, right?"
                    text="Pre-filled Weights & Reps"
                />

                <DividerLine />

                <FeatureBlock
                    imgSrc={ WorkoutTemplate }
                    subtext="Push day, pull day, legs day, any day except rest day. Save a workout and start it anytime with 1 click."
                    text="Build Templates"
                />
                
                <DividerLine />

                <FeatureBlock
                    imgSrc={ ScheduleWorkout }
                    subtext="Schedule next week, the next month, hell the whole year if you want. All you have to do is show up!"
                    text="Schedule Workouts"
                />

                <DividerLine />

                <FeatureBlock
                    imgSrc={ ExerciseShow }
                    subtext="Your templates are built. Your workouts are scheduled. There's only one thing left to do. Get after it!"
                    text="Break PRs"
                />
            </div>
        </div>
    )
}
