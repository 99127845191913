import React from 'react'

const ROOT_DESKTOP = 'w-1/2 flex flex-col items-left justify-center'
const ROOT_MOBILE = 'px-4 flex flex-col items-left justify-center'

const HEADER_DESKTOP = {
    fontSize: '36px'
}

const HEADER_MOBILE = {
    fontSize: '24px'
}

const PARAGRAPH_DESKTOP = {
    fontSize: '24px'
}

const PARAGRAPH_MOBILE = {
    fontSize: '18px'
}


export default function SectionText({ isMobileDevice, title, p1, p2, p3, showLink = false }) {
  return (
    <div className={ isMobileDevice ? ROOT_MOBILE : ROOT_DESKTOP }>
        <h3
            className="font-black mb-2"
            style={ isMobileDevice ? HEADER_MOBILE : HEADER_DESKTOP }
        >
            { title }
        </h3>

        <div
            style={ isMobileDevice ? PARAGRAPH_MOBILE : PARAGRAPH_DESKTOP }
        >
            <p className="mb-8">
                { p1 }
            </p>

            <p className="mb-8">
                { p2 }
            </p>

            <p className="mb-8">
                { p3 }
            </p>

            {
                renderLink({ showLink })
            }
        </div>
    </div>
  )
}

function renderLink({ showLink }) {
    if ( !showLink ) return null

    return (
        <a
            className="underline"
            target="blank"
            href="https://developers.google.com/web/tools/lighthouse"
        >
            Lean more about Lighthouse &gt;
        </a>
    )
}