import React from 'react'

const DESKTOP_STYLE = 'w-2/3 m-auto text-center font-black my-96'
const MOBILE_STYLE = 'm-4 text-center font-black my-64'

const DESKTOP_FONT_SIZE = {
    fontSize: '64px',
}

const MOBILE_FONT_SIZE = {
    fontSize: '24px',
}


export default function NoBullshit({ isMobileDevice }) {
    return (
        <div className={ isMobileDevice ? MOBILE_STYLE : DESKTOP_STYLE }>
            <h2
                className=""
                style={ isMobileDevice ? MOBILE_FONT_SIZE : DESKTOP_FONT_SIZE }
            >
                We’re a no bullshit kinda company.
                We know you’ve seen and heard every pitch in the books and we’re not here to waste your time, so what do you say?
                Let’s get to it.
            </h2>
        </div>
    )
}
