import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'

import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom'

import Home from './Pages/Home/Home'
import MyLiftLog from './Pages/MyLiftLog/MyLiftLog'

import ErrorPage from './Pages/ErrorPage'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <ErrorPage />,
    children: [],
  },
  {
    path: 'apps/myliftlog',
    element: <MyLiftLog />,
    errorElement: <ErrorPage />,
    children: [],
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <RouterProvider
      router={ router }
    />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
