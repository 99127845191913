import React from 'react'
import PropTypes from 'prop-types'

const MOBILE_STYLE = 'h-16 w-auto mb-4'
const DESKTOP_STYLE = 'h-36 w-auto mb-24'


export default function SectionHeader({ isMobileDevice, src }) {
    return (
        <img
            className={ isMobileDevice ? MOBILE_STYLE : DESKTOP_STYLE }
            src={ src }
            alt=""
        />
    )
}
